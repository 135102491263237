<template>
  <div class="tab-content1">
    <img src="../assets/tab1/1.png" class="banner">
    <div class="content">
      <img src="../assets/tab1/2.png">
      <img src="../assets/tab1/3.png">
      <img src="../assets/tab1/4.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabContent1'
}
</script>

<style scoped>
.banner{
  height: 830px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

</style>
