<template>
  <div class="tab-content2">
    <img src="../assets/tab4/1.png" class="banner">
    <div class="content">
      <img src="../assets/tab4/2.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabContent4'
}
</script>

<style scoped>
.banner{
  height: 530px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 56px auto;
 }
</style>
