<template>
  <div class="tab-content2">
    <img src="../assets/tab2/1.png" class="banner">
    <div class="content">
      <img src="../assets/tab2/2.png">
    </div>
      <img src="../assets/tab2/3.png">
    <div class="content">
      <img src="../assets/tab2/4.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabContent2'
}
</script>

<style scoped>
.banner{
  height: 680px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

</style>
